import React from "react";
import IndexPage from "./index"

const IndexPageRU = () => (
  <IndexPage i18n="ru"/>
);

export default IndexPageRU;


